import React from 'react';
import { connect } from 'react-redux';

import * as backdropActionCreators from './actions';

import styles from './index.module.sass';

export const Backdrop = ({ visible, actionType, hide, onClick = null }) => {
  const handleClick = () => {
    hide();

    if ( onClick ) {
      onClick();
    }
  };

  const backdropClass = actionType === backdropActionCreators.SHOW_BACKDROP ? styles.backdropSelf : styles.backdrop;

  return visible ? (
    <div className={`${backdropClass} v2-cart__backdrop position-fixed`} onClick={handleClick} />
  ) : null;
};

const mapStateToProps = ( state ) => ({
  visible: state.Backdrop.visible,
  actionType: state.Backdrop.actionType,
});

const mapDispatchToProps = {
  hide: () => backdropActionCreators.hide(),
};

export default connect( mapStateToProps, mapDispatchToProps )( Backdrop );
